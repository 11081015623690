:root {
  --dynamic-chat-height: 327px; /* Default value */
}

body {
  font-family: 'Quicksand';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes pulseShadow {
  0% {
    box-shadow: 0px 0px 10px rgba(66, 153, 225, 0);
  }
  50% {
    box-shadow: 0px 0px 10px rgba(66, 153, 225, 0.6);
  }
  100% {
    box-shadow: 0px 0px 10px rgba(66, 153, 225, 0);
  }
}

.pulseShadow {
  animation: pulseShadow 2s ease-in-out infinite;
}

.chatbot-parent-container {
  box-shadow:rgba(0, 0, 0, 0.2) 0px 0px 10px;
  z-index:500;
}

.react-chatbot-kit-chat-header {
  padding:15px 10px 16px 10px;
  text-align: center;
  font-size:1.1em;
  font-weight:bold;
  background-color:#FFCC33;
  border-radius: 13px 13px 0px 0px;
  color:black;
}

.react-chatbot-kit-chat-message-container {
  border-radius: 0px 0px 16px 16px;
  padding:15px 15px;
  overflow-y:scroll;
  overflow-x:hidden;
}

@media (max-width: 767px) {
  .react-chatbot-kit-chat-message-container {
    height: var(--dynamic-chat-height);
  }
}

@media (min-width: 768px) {
  .react-chatbot-kit-chat-message-container {
    height: 397px;
  }
}

@media (min-width: 992px) {
  .react-chatbot-kit-chat-message-container {
    height: 447px;
  }
}

.react-chatbot-kit-chat-bot-message {
  padding-bottom:5px;
}

.react-chatbot-kit-user-chat-message {
  text-align: right;
  padding:5px;
}

.react-chatbot-kit-chat-bot-avatar, .react-chatbot-kit-user-avatar {
  display:none;
}

.react-chatbot-kit-chat-input-container {
  position:absolute;
  bottom:0px;
  width:100%;
  height:45px;
  border-radius: 0px 0px 16px 16px;
}

.react-chatbot-kit-chat-input-form {
  height:40px;
}

.react-chatbot-kit-chat-input {
  width: calc(100% - 80px);
  height:45px;
  border-radius: 0px 0px 0px 16px;
  padding: 5px 15px;
  border-top: solid 1px grey;
}

.react-chatbot-kit-chat-btn-send {
  width:80px;
  position:absolute;
  right:0px;
  padding:10px;
  height:45px;
  border-radius: 0px 0px 16px 0px;
}

.react-chatbot-kit-chat-btn-send svg {
  width:25px;
  fill:white;
  margin: 0 auto;
  padding-right:5px;
}